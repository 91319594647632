body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pageContent{
  margin-top: 25%;
}
.userTable{
  background-color:white;
  color: black;
}

.card h4{
  color: black;
}

.form-group {
  margin-bottom: 0.5em !important;
}

.pageContent h2{
  margin-bottom: 1.5em;
}

.card{
  padding: 2em;
}
 
.gray{
  color: gray;
}

.hidden{
  display: none;
}

.font-white{
  color: white !important;
}

.homeTable th{
  background-color: lightgrey;
  color: black;
  margin: 10px;
} 

.homeTable td{
  background-color: #373737;
  color: black;
  margin: 10px;
} 

.homeTable th,.homeTable td{
  border: 5px solid #4f4f4f !important;
  text-align: center !important;
  vertical-align: top !important;
}

.pr0{
  padding-right: 0 !important;
}

.grayBlock{
  background-color: lightgray;
  color:black;
  text-align: center;
  vertical-align: middle
}

.grayBlock label{
  margin: 0;
}

.card h4{
  margin-bottom: 2em;
}

.pageTitle {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 2.5em;
}
 

.h3{
  font-size: 20px;
  color: lightgrey;
}

.price-detail .priceper{
  font-size: 13px;
  color: lightgrey;
  float: left;
  width: 100%;;
}

.description{
  font-size: 16px;
    text-align: center;
}

.price-detail .price{
  font-size: 45px;
  width: 100%;
  float: left; 
  color: black;
}

.price-detail{
margin-bottom: 1em;
float: left;
width: 100%;;
}

.adminPageTitle{
  font-weight: bold !important;
}

.mg1{
  margin-bottom: 1em;
}

h1,h2,h3,h4,h5,h6{
  font-weight: normal !important;
}

.login-form {
  width: 350px;
}

.panel { 
  margin: 0 auto;
  padding: 30px 0;
   color: #434343;
  border-radius: 1px;
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  padding: 30px;
  width: 370px;
}
 

.register-panel hr{
  margin-bottom: 1.5em;  
} 

.float-left{
  float: left;
}

.register-panel { 
  width: 20%;
  margin: 0 auto;
  padding: 30px 0;
   color: #434343;
  border-radius: 1px;
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.register-panel span{
  color: lightgray;
  font-size: 14px;
  margin-left: 1em;
}
  
.panel h4 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}

.panel label,.register-panel label{
  font-size: 18px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.img-responsive{
  display: block;
  max-width: 100%;
  height: auto;
}

/* toggle style */
.multiSelectButton{
  background-color: white;
  color: black;
  border: 0px;
  cursor: pointer;
}

.multiSelectButton.onSelect{
  background-color: green !important; 
  color: white !important;
}

.multiSelectButton.offSelect{
  background-color:red !important;
  color: white !important; 
}

/*# sourceMappingURL=simple.css.map*/
 
.appBody {
  background-color: #282828;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  background-color: #393939;
  min-height: 100vh;     
  color: white;
  max-width: 100%;
}
  
/* .modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
} */
